<template>
  <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
    <div class="card">
      <div class="card-header">Salary</div>
      <div class="card-body">
        <div class="row">
          <div id="slide-panel4" class="slide-panel-light" style="position: fixed; width: 275px; transition: all 450ms ease 0s; height: 100%; top: 0px; right: -275px; z-index: 1049;"><h3>Set Salary</h3>
            <form>
              <div class="row">
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-6 col-6">
                  <h6>Set Hourly Salary($)</h6>
                </div>
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 pt-0 pb-0">
                  <div class="form-group">                                                 
                    <v-text-field type="text" v-model="update.hourly" placeholder="$" outlined></v-text-field>
                  </div>
                </div>
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-6 col-6 pt-0 pb-0">
                  <h6>Website Charge($)</h6>
                </div>
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 pb-0">
                  <div class="form-group">                                                 
                    <v-text-field type="text" v-model="update.website_hourly" placeholder="$" outlined></v-text-field>                  </div>
                  <p>Total: ${{(this.update.hourly||this.update.website_hourly)?((this.update.hourly?parseInt(this.update.hourly):0)+(this.update.website_hourly?parseInt(this.update.website_hourly):0)):0}}</p>
                  <hr>
                </div>
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-6 col-6 pb-0">
                  <h6>Set Monthly Salary($)</h6>
                </div>
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 pb-0">
                  <div class="form-group"> 
                    <v-text-field type="text" v-model="update.monthly" placeholder="$" outlined></v-text-field>
                  </div>
                </div>
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-6 col-6 pt-0 pb-0">
                  <h6>Website Charge($)</h6>
                </div>
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 pb-0">
                  <div class="form-group">                                                 
                    <v-text-field type="text" v-model="update.website_monthly" placeholder="$" outlined></v-text-field>
                  </div>
                  <p>Total: ${{(this.update.monthly||this.update.website_monthly)?(this.update.monthly?parseInt(this.update.monthly):0)+(this.update.website_monthly?parseInt(this.update.website_monthly):0):0}}</p>
                  <hr>
                </div>
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-6 col-6 pb-0">
                  <h6>Set Yearly Salary($)</h6>
                </div>
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 pb-0">
                  <div class="form-group">
                    <v-text-field type="text" v-model="update.yearly" placeholder="$" outlined></v-text-field>
                  </div>
                </div>
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-6 col-6 pt-0 pb-0">
                  <h6>Website Charge($)</h6>
                </div>
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 pb-0">
                  <div class="form-group">                                                 
                    <v-text-field type="text" v-model="update.website_yearly" placeholder="$" outlined></v-text-field>
                  </div>
                  <p>Total: ${{(this.update.yearly||this.update.website_yearly)?(this.update.yearly?parseInt(this.update.yearly):0)+(this.update.yearly?parseInt(this.update.website_yearly):0):0}}</p>
                  <hr>
                </div>
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                  <div class="form-group">
                    <a v-on:click="setSalary" class="btn btn-success text-white">Save</a>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 setbtn">
            <a v-on:click="openSlider()" class="btn btn-default" id="trigger4">Set Salary</a>
          </div>
          <div class="card request-list-table table-responsive pr-0 pl-0">
            <table class="table">
              <thead>
                <tr>
                  <th>Class Method </th>
                  <th>Tutor Salary</th>
                  <th>Website Charge</th>
                  <th>Total</th>
                </tr>
              </thead>
              <tbody v-if="tutor_salary.hourly || tutor_salary.monthly || tutor_salary.yearly">
                <tr>
                  <td class="requester-name pl-5">Hourly</td>
                  <td class="wedding-date pl-5" v-if="tutor_salary.hourly">${{tutor_salary.hourly}}</td><td v-else>-</td>
                  <td class="requester-id text-success pl-5" v-if="tutor_salary.website_hourly">${{tutor_salary.website_hourly}}</td><td v-else>-</td>
                  <td class="requester-id text-success pl-5" v-if="tutor_salary.hourly_total">${{tutor_salary.hourly_total}}</td><td v-else>-</td>
                </tr>
                <tr>
                  <td class="requester-name pl-5">Monthly</td>
                  <td class="wedding-date pl-5" v-if="tutor_salary.monthly">${{tutor_salary.monthly}}</td><td v-else>-</td>
                  <td class="requester-id text-success pl-5" v-if="tutor_salary.website_monthly">${{tutor_salary.website_monthly}}</td><td v-else>-</td>
                  <td class="requester-id text-success pl-5" v-if="tutor_salary.monthly_total">${{tutor_salary.monthly_total}}</td><td v-else>-</td>
                </tr>
                <tr>
                  <td class="requester-name pl-5">Yearly</td>
                  <td class="wedding-date pl-5" v-if="tutor_salary.yearly">${{tutor_salary.yearly}}</td><td v-else>-</td>
                  <td class="requester-id text-success pl-5" v-if="tutor_salary.website_yearly">${{tutor_salary.website_yearly}}</td><td v-else>-</td>
                  <td class="requester-id text-success pl-5" v-if="tutor_salary.yearly_total">${{tutor_salary.yearly_total}}</td><td v-else>-</td>
                </tr>
              </tbody>
              <tbody v-else>
                <tr>
                  <td></td>
                  <td><div class="empty-data">Not set salary</div></td>
                  <td></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
export default {
  name:'TutorSalary',
  props:['tutor'],
  data(){
    return{
      update:{
        tutor_id:null,
        hourly:null,
        monthly:null,
        yearly:null,
        website_hourly:null,
        website_monthly:null,
        website_yearly:null,
        hourly_total:null,
        monthly_total:null,
        yearly_total:null,
      },
      tutor_salary:{
        hourly:null,
        monthly:null,
        yearly:null,
        website_hourly:null,
        website_monthly:null,
        website_yearly:null,
        hourly_total:null,
        monthly_total:null,
        yearly_total:null
      },
    }
  },
  beforeMount(){
    // this.tutor_salary = (this.tutor.salary!=null)?this.tutor.salary:{};
    if(this.tutor.salary != null){
      this.tutor_salary = this.tutor.salary;
    }
    this.update.tutor_id = this.tutor.tutor_id;
    this.update.hourly = (this.tutor_salary)?this.tutor_salary?.hourly:null;
    this.update.monthly = (this.tutor_salary)?this.tutor_salary.monthly:null;
    this.update.yearly = (this.tutor_salary)?this.tutor_salary.yearly:null;
    this.update.website_hourly = (this.tutor_salary)?this.tutor_salary.website_hourly:null;
    this.update.website_monthly = (this.tutor_salary)?this.tutor_salary.website_monthly:null;
    this.update.website_yearly = (this.tutor_salary)?this.tutor_salary.website_yearly:null;
    this.update.hourly_total = (this.update.hourly)?parseInt(this.update.hourly)+parseInt(this.update.website_hourly):0;
    this.update.monthly_total = (this.update.monthly)?parseInt(this.update.monthly)+parseInt(this.update.monthly):0;
    this.update.yearly_total = (this.update.yearly)?parseInt(this.update.yearly)+parseInt(this.update.website_yearly):0;
  },
  methods:{
    openSlider(){
      document.getElementById('slide-panel4').style.right = '0px';
      document.getElementById('slide-panel4').style.width = '275px';
      document.getElementById('slide-reveal-overlay').style.display = 'block';
    },
    closeSlideBar(){
      document.getElementById('slide-panel4').style.right = '-275px';
      document.getElementById('slide-panel4').style.width = '0px';
      document.getElementById('slide-reveal-overlay').style.display = 'none';
    },
    setSalary(e){
      e.preventDefault();
      this.update.hourly_total = (this.update.hourly||this.update.website_hourly)?((this.update.hourly?parseInt(this.update.hourly):0)+(this.update.website_hourly?parseInt(this.update.website_hourly):0)):0;
      this.update.monthly_total = (this.update.monthly||this.update.website_monthly)?(this.update.monthly?parseInt(this.update.monthly):0)+(this.update.website_monthly?parseInt(this.update.website_monthly):0):0;
      this.update.yearly_total = (this.update.yearly||this.update.website_yearly)?(this.update.yearly?parseInt(this.update.yearly):0)+(this.update.yearly?parseInt(this.update.website_yearly):0):0;
      this.tutor_salary={};
      this.tutor_salary.hourly = this.update.hourly;
      this.tutor_salary.monthly = this.update.monthly;
      this.tutor_salary.yearly = this.update.yearly;
      this.tutor_salary.website_hourly = this.update.website_hourly;
      this.tutor_salary.website_monthly = this.update.website_monthly;
      this.tutor_salary.website_yearly = this.update.website_yearly;
      this.tutor_salary.hourly_total = this.update.hourly_total;
      this.tutor_salary.monthly_total = this.update.monthly_total;
      this.tutor_salary.yearly_total = this.update.yearly_total;

      axios.post(process.env.VUE_APP_TUTION_SITE_API+'/tutor_salary',this.update,{headers:{"Authorization":'Bearer '+(this.$cookies.get('tution-admin').token)}}).then(res => {
        if(res.data.message == 'Salary Created successfully'){          
          this.closeSlideBar();
          this.tutor.salary = this.tutor_salary;
        }
      })
    }
  }
}
</script>

<style scoped>
.card {
    border: none;
    -webkit-box-shadow: 0 0 0 1px rgb(61 70 79 / 5%), 0 1px 3px 0 rgb(61 70 79 / 15%);
    box-shadow: 0 0 0 1px rgb(61 70 79 / 5%), 0 1px 3px 0 rgb(61 70 79 / 15%);
    margin-bottom: 30px;
    border-radius: 2px;
}
.card-header {
    background: #fbfbfb;
    border-bottom: 1px solid #e6e5e7;
    color: #252328;
    padding: .75rem 1.25rem;
    margin-bottom: 0;
}
.slide-panel-light {
    background-color: #fff;
    padding: 30px;
    text-align: left;
    overflow: scroll;
}
.setbtn a.btn {
    float: right !important;
    width: 200px;
}
.request-list-table .table {
    font-size: 14px;
}
.request-list-table table th {
    background-color: #fff;
    font-weight: 400;
    border-bottom: 1px solid #eee;
    padding: 14px 20px;
    color: #252328;
}
.request-list-table table td {
    background-color: #fdfdfd;
    border-bottom: 1px solid #eee;
    padding: 10px 16px;
}
</style>